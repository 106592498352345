import { render, staticRenderFns } from "./DetailAnnouncementExternal.html?vue&type=template&id=1cdb072c&scoped=true&"
import script from "./DetailAnnouncementExternal.vue?vue&type=script&lang=js&"
export * from "./DetailAnnouncementExternal.vue?vue&type=script&lang=js&"
import style0 from "./DetailAnnouncementExternal.scss?vue&type=style&index=0&id=1cdb072c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cdb072c",
  null
  
)

export default component.exports